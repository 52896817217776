/*
 * Copyright (C) Yağızhan IŞIK - 2022.
 * This app is developed for internal use only and may not be exported, shared, edited or distributed under any circumstances.
 */


let limitedMode = false;

let appFrame = document.querySelector('iframe');
let appNavbarHeader = document.querySelector('bx-header-nav');

const LimitedModeWhitelistedFrames = ['welcome-ceremony'];

//AFUserLockdown
if (localStorage.getItem('AFUserLockdown') === 'true') {
	limitedMode = true;
	appNavbarHeader.style.cssText = 'display: none !important;';
}

// AFPreferredStart
if (localStorage.getItem('AFPreferredStart')) {
	appFrame.src = '/frames/' + localStorage.getItem('AFPreferredStart');
}

let DesktopClientInfo = {
	modern: false,
	detected: false
}
// Desktop App Communication
if(navigator.userAgent.indexOf(' tys-electron/') > -1){
	DesktopClientInfo.detected = true;
	// TYS electron app, check for modern libs injection
	if(AppNative && AppNative.modernApp){
		DesktopClientInfo.modern = true;

	}
}